import React from 'react';
import BackgroundLaptop from "./background/BackgroundLaptop"
import { Link } from "gatsby"

const ContactUs =()=>(    <section className="section-padding-top section-contact">
<div className="container contact-box">
  <div className="contact-box-child">
    <BackgroundLaptop />
  </div>
  <div className="row">
    <div className="col-lg-8">
      <div className="col-limit-3">
        <div className="heading-top"></div>
        <h3 className="heading-3 mb-4">
          Ready to transform your business with us?
        </h3>
      </div>
      <p className="paragraph-3 text-white mb-4">
      We are dedicated to assisting you at every step, offering a comprehensive range of tools and expert consultations to support you along the way. Don't hesitate to reach out to us, as together, we can efficiently resolve any challenges you may encounter.
      </p>
      <Link to="/contact" className="line-below bigger line-reverse text-white">
        Contact us
      </Link>
    </div>
  </div>
</div>
</section>);

export default ContactUs;