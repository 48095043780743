import React from "react"

import Layout from "../components/layout"
import PageHeader from "../components/PageHeader"
import BackgroundEducation from "../components/background/BackgroundEducation"
import BackgroundConsultation from "../components/background/BackgroundConsultation"
import ContactUs from "../components/ContactUs"

const Certification = () => (
  <Layout>
    <PageHeader title="Education &amp; Consultation" subtitle="We provide training & consultancy in cloud and related technologies" />
    <section className="section-padding-top">
      <div className="container">
        <div className="row row-mobile-reverse">
          <div className="col-lg-6">
            <div className="col-limit-3 mt-4">
              <div className="heading-top"></div>
              <h2 className="heading-2 mb-5">Education</h2>
            </div>
            <p className="paragraph-3">
            VoerEir has during the last years been giving courses to several Tier1 operators. These courses have typically been tailored after customers’ needs and wishes. Based on who the audients were and how much time the customer wanted to invest.
            </p>
            <p className="paragraph-3">
            We have been giving two kinds of course; Touchstone training including hands-on exercises, and around technology issues that impact NFV strategies.
            </p>
            <p className="paragraph-3">
            Based on this experience we have created two course-packages:
            </p>
            <ul className="theme-ui">
              <li>NFVI technologies What you need to know</li>
              <li>Testing with Touchstone</li>
            </ul>
            <p className="paragraph-3 mt-3">
            Both can be done as 1 – 3 days workshops, with topics according to the document the links below points at.
            </p>
          </div>
          <div className="col-lg-6 text-center mobile-margin-top">
            <div className="img-wrap box-bottom-left width-100 height-400">
              <BackgroundEducation />
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className="section-padding-top">
      <div className="container">
        <div className="row">
        <div className="col-lg-6 text-center">
            <div className="img-wrap box-bottom-right width-100">
              <BackgroundConsultation />
            </div>
          </div>
          <div className="col-lg-6">
            <div className="col-limit-3 mt-4">
              <div className="heading-top"></div>
              <h2 className="heading-2 mb-5">Consultancy Services</h2>
            </div>
            <p className="paragraph-3">
            VoerEir provides technical consulting based on a very long and deep understanding of the technical challenges of NFV transformation. T he cornerstone of technical consulting is in-depth knowledge of the interworking between VNF application and NFVI infrastructures. Understanding the issues around this interworking is essential to secure a successful NFV transformation, and many examples exist where lack of understanding has led to severe delays for operators’ NFV transformation programs.
            </p>
            <p className="paragraph-3">
            Our experience in Technical consulting includes; writing RFQ for NFVI procurement, evaluation of RFQ answers both for NFVIs and VNFs, Cloud testing and troubleshooting.
            </p>
            <p className="paragraph-3">
            VoerEir has developed the V8LS methodology and we provide transformational consulting based on this methodology. The cornerstone in the method is a clear definition of the demarcation point between the VNF applications and the NFVI cloud infrastructure. A clear demarcation point is critical for successful NFV transformation and impacts the way VNF applications and NFVI cloud infrastructure are designed, procured, deployed, tested, validated and operated. VoerEir has a unique industry understanding how the technical considerations impact the overall transformation.
            </p>
          </div>

        </div>
      </div>
    </section>
        <ContactUs/>
  </Layout>
)

export default Certification
