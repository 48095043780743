import React, {Fragment} from 'react';
import SEO from "./seo"

const PageHeader = ({title="page",subtitle, displayTitle})=>(
    <Fragment>
    <SEO title={title} />
    <section className="page-header">
    <div className="header-content">
            <h2>{displayTitle || title}</h2>
            {subtitle && <p className="text-white">{subtitle}</p>}
          </div>
    </section>
    </Fragment>
)

export default PageHeader;